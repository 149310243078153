import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
// import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));

// inicio
const MiSolicitud = React.lazy(() => import("../pages/inicio/mi-solicitud"));
// const MiSolicitudOld = React.lazy(() => import("../pages/inicio/MiSolicitud"));
const Prestamos = React.lazy(() => import("../pages/inicio/prestamos"));
const Notificaciones = React.lazy(() => import("../pages/inicio/notificaciones"));
const Perfil = React.lazy(() => import("../pages/inicio/perfil"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// Mi Solicitud
const miSolicitudRoutes = {
  path: "/mi-solicitud",
  name: "Mi Solicitud",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "archive",
  element: <MiSolicitud />,
};
// Prestamo
const prestamosRoutes = {
  path: "/prestamos",
  name: "Préstamos",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "credit-card",
  element: <Prestamos />,
};
// Notificaciones
const notificacionesRoutes = {
  path: "/notificaciones",
  name: "Motificaciones",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "bell",
  element: <Notificaciones />,
};
// Perfil
const perfilRoutes = {
  path: "/perfil",
  name: "Perfil",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "user",
  element: <Perfil />,
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  miSolicitudRoutes,
  prestamosRoutes,
  notificacionesRoutes,
  perfilRoutes,
  // pagesRoutes,
];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
