import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { isUserAuthenticated, getLoggedInUser } from "../helpers/api/apiCore";

interface PrivateRouteProps {
  roles?: string[];
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles }) => {
  const location = useLocation();

  if (!isUserAuthenticated()) {
    // not logged in so redirect to login page with the return url
    return (
      <Navigate
        to={{
          pathname: "/auth/login",
          search: `next=${location.pathname}`,
        }}
        replace
      />
    );
  }

  const loggedInUser = getLoggedInUser();

  // check if route is restricted by role
  if (roles && loggedInUser && roles.indexOf(loggedInUser.role) === -1) {
    // role not authorised so redirect to home page
    return <Navigate to={{ pathname: "/" }} replace />;
  }

  // authorised so return component
  return <Outlet />;
};

export default PrivateRoute;