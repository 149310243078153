import { axios } from "./apiCore";

// account
function login(params: { username: string; password: string }) {
  const baseUrl = "/auth/login/";
  return axios.post(baseUrl, params);
}

function logout() {
  const baseUrl = "/auth/logout/";
  return axios.post(baseUrl, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/auth/register/";
  return axios.post(baseUrl, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/auth/forget-password/";
  return axios.post(baseUrl, params);
}

export { login, logout, signup, forgotPassword };
