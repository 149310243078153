import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

// layout constants
import { LayoutTypes } from "../constants/layout";

// store
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import TwoColumnLayout from "../layouts/TwoColumn/";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import PrivateRoute from "./PrivateRoute"; // Importar el componente PrivateRoute

interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();

  return (
    <React.Fragment>
      <Routes>
        {/* Ruta para redirigir la raíz */}
        <Route path="/" element={<Navigate to="/mi-solicitud" replace />} />

        {/* Rutas públicas */}
        {publicProtectedFlattenRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <DefaultLayout {...props} layout={layout}>
                {route.element}
              </DefaultLayout>
            }
            key={idx}
          />
        ))}

        {/* Rutas protegidas */}
        <Route element={<PrivateRoute />}>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<Layout {...props}>{route.element}</Layout>}
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;